import "./Projects.css";

import { motion } from "framer-motion";

import { ProjectThumbnail } from "../ProjectThumbnail/ProjectThumbnail";

export const Projects = ({ projects }) => {
  const calculateInverseTransition = (originalIndex) => {
    const lastIndex = projects.length - 1;
    const inverseIndex = lastIndex - originalIndex;
    return inverseIndex;
  };

  return (
    <div className="projects">
      <ul className="projects-list">
        {projects.map((project) => {
          return (
            <motion.li
              className="project-list-element"
              key={project.id}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
              transition={{
                duration: 0.4,
                delay: 0.1 * calculateInverseTransition(project.id),
              }}
            >
              <ProjectThumbnail project={project} />
            </motion.li>
          );
        })}
      </ul>
    </div>
  );
};
