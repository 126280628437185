import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { logInUserService } from "../../services";

import { useModal } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";

import { Button } from "../Button/Button";

export const LoginUser = () => {
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [, setModal] = useModal(false);

  const handleForm = async (e) => {
    e.preventDefault();

    try {
      const data = await logInUserService({ email, password });
      const token = `Bearer ${data}`;
      login(token);
      setModal(false);
      window.location.reload();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="login-user one-col-modal">
      <h1 className="modal-title">log in</h1>
      <form className="modal-form" id="flex-form" onSubmit={handleForm}>
        <label htmlFor="email-form">email</label>
        <input
          type="email"
          name="email-form"
          id="email-form"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        ></input>
        <label htmlFor="password-form">password</label>
        <input
          type="password"
          name="password-form"
          id="password-form"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        ></input>
        <Button
          leftText={"login"}
          rightText={String.fromCharCode(parseInt("&#8594;".substring(2), 10))}
          buttonStyle={"red-button"}
        />
        {error ? <p className="error-text">{error}</p> : null}
      </form>
    </div>
  );
};
