import "./HomePage.css";

import useProjects from "../../hooks/useProjects";

import { Loading } from "../../components/Loading/Loading";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";

import { Projects } from "../../components/Projects/Projects";
import { Presentation } from "../../components/Presentation/Presentation";
import { Footer } from "../../components/Footer/Footer";

export const HomePage = () => {
  const { projects, loading, error } = useProjects();

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="home-page">
      <Presentation />
      <Projects projects={projects} />
      <Footer />
    </div>
  );
};
