import "./Footer.css";

import { useContext } from "react";
import { NavLink } from "react-router-dom";

import { useUser } from "../../hooks/useUser";
import { useModal } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";

import { Loading } from "../Loading/Loading";
import { ErrorMessage } from "../ErrorMessage/ErrorMessage";
import { About } from "../About/About";
import { LoginUser } from "../LoginUser/LoginUser";
import { CreateProject } from "../CreateProject/CreateProject";
import { ModifyUserData } from "../ModifyUserData/ModifyUserData";
import { Contact } from "../Contact/Contact";
import { Button } from "../Button/Button";
import { ModifyPhotosCarousel } from "../ModifyPhotosCarousel/ModifyPhotosCarousel";

export const Footer = () => {
  const { token, logout } = useContext(AuthContext);
  const { user, loading, error } = useUser();
  const [, setModal] = useModal();

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="footer">
      {/* ABOUT */}

      <About user={user} />

      {/* UI FOOTER (JUST DESKTOP) */}

      <div className="ui-footer">
        {token ? (
          <>
            <p
              onClick={() => setModal(<CreateProject />)}
              id="ui-button-create-project"
            >
              <NavLink>
                <Button
                  leftText="&#8594;"
                  rightText="create project"
                  buttonStyle="no-box-button"
                />
              </NavLink>
            </p>

            <p
              onClick={() => setModal(<ModifyUserData />)}
              id="ui-button-modify-user-data"
            >
              <NavLink>
                <Button
                  leftText="&#8594;"
                  rightText="modify user data"
                  buttonStyle="no-box-button"
                />
              </NavLink>
            </p>

            <p
              onClick={() => setModal(<ModifyPhotosCarousel user={user} />)}
              id="ui-button-modify-photos-carousel"
            >
              <NavLink>
                <Button
                  leftText="&#8594;"
                  rightText="modify photos carousel"
                  buttonStyle="no-box-button"
                />
              </NavLink>
            </p>

            <p onClick={() => logout()} href="/" id="ui-button-logout">
              <Button
                leftText="&#8594;"
                rightText="logout"
                buttonStyle="no-box-button"
              />
            </p>
          </>
        ) : (
          <p onClick={() => setModal(<LoginUser />)} id="ui-button-login">
            <NavLink>
              <Button
                leftText="&#8594;"
                rightText="login"
                buttonStyle="no-box-button"
              />
            </NavLink>
          </p>
        )}
      </div>

      {/* CONTACT */}

      <Contact user={user} />
    </div>
  );
};
