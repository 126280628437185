import "./Button.css";

export const Button = ({ leftText, rightText, buttonStyle, onClick }) => {
  return (
    <button id={buttonStyle} className="button" onClick={onClick}>
      <p>{leftText}</p>
      <p>{rightText}</p>
    </button>
  );
};
