import { Link } from "react-router-dom";

import { ContactLink } from "../ContactLink/ContactLink";

export const Contact = ({ user }) => {
  const currentYear = new Date().getFullYear();

  return (
    <div className="contact">
      <div className="city-telephone-links-contact">
        <p id="city-contact">{user.cityCountry}</p>
        <ContactLink
          id="email-contact-link"
          linkTextL="Email"
          linkTextR={user.email}
          isLowerCase="is-lower-case"
        />
        <ContactLink
          id="telephone-contact-link"
          linkTextL="Tel."
          linkTextR={user.telephone}
        />
        {user.url_customLink_1 ? (
          <ContactLink
            id="external-link-one"
            linkTextL={`${user.web_customLink_1}`}
            linkTextR={user.user_customLink_1}
            linkUrl={user.url_customLink_1}
          />
        ) : null}
        {user.url_customLink_2 ? (
          <ContactLink
            id="external-link-two"
            linkTextL={`${user.web_customLink_2}`}
            linkTextR={user.user_customLink_2}
            linkUrl={user.url_customLink_2}
          />
        ) : null}
        {user.url_customLink_3 ? (
          <ContactLink
            id="external-link-three"
            linkTextL={`${user.web_customLink_3}`}
            linkTextR={user.user_customLink_3}
            linkUrl={user.url_customLink_3}
          />
        ) : null}
      </div>
      <Link className="logo-copyright-contact" to={`/`}>
        <svg
          version="1.1"
          id="contact-logo"
          x="0px"
          y="0px"
          viewBox="0 0 720 248.2"
        >
          <g id="OO">
            <path
              className="st0"
              d="M718.3,104.5c-5.8-32.9-23.1-57.6-51.5-73.5c-27.8-15.6-58.6-20.6-91.5-14.8l-0.3,0.1
		c-19,4.1-36.1,10.6-50.7,19.2c-14.7,8.7-27.4,19.9-37.5,33.2c-20.5,26.8-27.7,58.2-21.5,93.4c5.2,29.7,20.8,52.7,46.2,68.3
		c18.4,11.3,38.9,17,61.3,17c8.2,0,16.7-0.8,25.4-2.3c19.4-3.4,37.1-9.4,52.6-17.7c15.6-8.4,29.3-19.4,40.9-32.7
		C715.2,167.8,724.1,137.4,718.3,104.5z M609.2,183.5c-9.6,1.7-17.5-2.7-23.7-13.2c-6.1-10-12-21.8-17.6-35.7
		c-5.6-13.8-9.2-24.8-10.6-33c-1.2-7-0.6-13,2.1-18c2.6-5,6.7-8.2,12.2-9.8c9.3-1.6,16.9,2,22.8,10.8c15.3,24,24.9,47.3,28.9,69.8
		C626.2,171.6,621.6,181.3,609.2,183.5z"
            />
            <path
              className="st1"
              d="M576.6,23.7c31.3-5.5,60.1-0.9,86.5,13.9c26.4,14.8,42.3,37.5,47.8,68.2c5.4,30.7-2.9,58.7-24.8,84.1
		c-22,25.4-51.7,41.3-89.1,47.9c-30.7,5.4-57.9,0.8-81.5-13.7c-23.6-14.6-37.9-35.6-42.7-63.2c-5.9-33.2,0.8-62.4,20.1-87.6
		C512.1,48.1,540,31.6,576.6,23.7z M571.5,73.8c-5.5,1.6-9.6,4.8-12.2,9.8c-2.6,5-3.3,11-2.1,18c1.4,8.2,5,19.2,10.6,33
		c5.6,13.8,11.5,25.7,17.6,35.7c6.2,10.5,14.1,14.9,23.7,13.2c12.4-2.2,17.1-11.9,14-29c-4-22.5-13.6-45.8-28.9-69.8
		C588.4,75.8,580.8,72.2,571.5,73.8z"
            />
            <path
              className="st0"
              d="M699,134.7c-2.8,1-8.9-31.3-39.1-61.4C636.7,50.3,613.8,43,615,40.6c1.3-2.8,32.9,2,55.9,24
		C699.5,91.8,702.1,133.6,699,134.7z"
            />
          </g>
          <g id="YY">
            <path
              className="st0"
              d="M553,27c-6.9-4.5-16.9-7.7-30.7-9.6c-14-1.9-25.3-1.5-34.6,1.4c-13.9,4.2-17.9,12.5-18.7,18.8
		c-0.7,5,0.8,10.7,4.5,17.5c2.1,3.9,3,7.3,2.6,10.2c-0.3,1.9-1.4,6.4-6.5,15.6c-2.8,5.1-4.9,6.6-5.7,6.9c-0.8-0.7-3-3.3-5.9-11.5
		c-4.5-12.5-4.5-17.3-4.3-18.8c0-0.1,0.3-1.8,3.5-5.5c4.2-4.9,6.4-9,7-12.9c1-7-0.3-17.1-11.9-24.9c-7.8-5.3-19.3-8.9-35.2-11.1
		c-12.8-1.7-25.6-2.8-38.1-3c-14.6-0.3-25.7,1.2-33.9,4.4c-12.2,4.9-15.7,13.2-16.6,19.3c-0.7,4.9,0.4,9.6,3.1,13.6
		c2.1,3.2,4.3,5.6,6.5,7.4c1.9,1.5,4.6,3.3,8.1,5.3c1.7,1.3,5.6,5.6,10.1,20.5l29.4,94.5c5.5,17.3,5.5,23.5,5.2,25.3
		c-0.4,3-1.4,5.2-3,6.8c-1.1,1.1-2.1,1.4-3.1,1.4c-0.2-0.3-0.4-0.8-0.7-1.7c-1.2-3.6-2.2-7.5-2.8-11.6c-0.9-5.9-3.7-11.7-8.4-17.1
		c-5-5.8-11.9-9.3-20.6-10.5c-10.3-1.4-19.8,1.2-28,7.7c-8.2,6.5-13,15.1-14.5,25.8c-1.9,14.2,2.4,26.8,13,37.4
		c10,10.1,23.7,16.4,40.7,18.7c4.7,0.6,9.2,1,13.7,1c8.5,0,16.7-1.2,24.4-3.5c11.8-3.5,22.4-9.5,31.5-17.8
		c8.6-7.9,16.1-16.3,22.4-25.2c6.1-8.7,12.4-19.1,18.7-31l31.7-59.7l0,0c8.2-15.6,14.7-26.4,19.4-32.1c4-4.8,8.1-7.6,12.7-8.5l0.2,0
		c8.1-2,13.6-4.1,17.3-6.8c3.1-2.2,7-6.6,8.1-14.5C565,40.3,561.2,32.4,553,27z"
            />
            <path
              className="st1"
              d="M499.5,107.6l-31.7,59.7c-6.2,11.7-12.3,21.8-18.2,30.2c-6,8.5-13.1,16.5-21.4,24
		c-8.3,7.5-17.8,12.9-28.6,16.1c-10.8,3.2-22.5,4-35,2.3c-15.4-2.1-27.5-7.6-36.4-16.6c-8.9-8.9-12.5-19.3-10.9-31.1
		c1.2-8.8,5.1-15.8,11.7-21c6.6-5.2,14.1-7.3,22.4-6.2c6.9,0.9,12.2,3.6,15.9,8c3.7,4.4,6,8.8,6.7,13.4c0.7,4.6,1.7,8.9,3.1,12.9
		c1.4,4,3.4,6.2,6,6.6c3.8,0.5,7.1-0.6,10-3.3c2.9-2.7,4.7-6.5,5.3-11.2c0.7-5.2-1.1-14.7-5.5-28.6l-29.4-94.4
		c-4-13.1-8.4-21.3-13.3-24.6c-3.4-1.9-5.8-3.5-7.5-4.8c-1.6-1.3-3.3-3.2-5-5.7c-1.7-2.5-2.3-5.3-1.9-8.4
		c1.7-12.1,15.9-17.8,42.9-17.3c12.3,0.2,24.7,1.2,37.2,2.9c29.1,4,42.7,13.2,40.7,27.6c-0.3,2.4-2.1,5.4-5.2,9
		c-3.2,3.7-4.9,6.8-5.3,9.4c-0.6,4.5,1,11.9,4.7,22.3c3.8,10.4,7.6,15.9,11.7,16.4c4.7,0.6,9.3-2.9,13.6-10.8
		c4.3-7.8,6.8-13.9,7.4-18.2c0.6-4.5-0.5-9.4-3.4-14.7c-2.9-5.3-4.1-9.7-3.6-13c0.8-5.9,5.3-10.1,13.5-12.6
		c8.2-2.5,18.6-2.9,31.4-1.1c25.1,3.4,36.8,11.2,35.1,23.2c-0.6,4.3-2.3,7.4-5.1,9.4c-2.8,2-7.7,3.9-14.7,5.6
		c-6.2,1.3-11.8,5-16.9,11.1C514.7,80.3,507.9,91.4,499.5,107.6z"
            />
            <path
              className="st0"
              d="M436.2,48.2c-0.3-1.8,0.6-6.8-0.9-11.5c-1.5-4.7-5.6-9.1-7-10.8c-3.7-4.6,6.3-3.6,12.5,0.1
		c5.2,3.1,5,5.7,5.2,7.9C446.3,38.8,437.1,53.7,436.2,48.2z"
            />
            <path
              className="st0"
              d="M539.7,57c-1.5-0.1-0.8-4.4-4.2-9.5c-1.4-2.2-3.1-3.7-4.6-5c-1.5-1.2-2.9-2-3.8-2.7c-1-0.7-1.6-1.2-1.4-1.8
		c0.4-1.3,5.4-2.5,9.1-2c1.2,0.2,4.8,0.7,7.1,2.3c2.8,1.8,3.7,3.8,4.3,6.7c0.2,0.8,0.2,1.6,0.1,2.4c0,1.6-0.6,3.1-1.3,4.5
		C543.3,55,540.8,57.1,539.7,57z"
            />
          </g>
          <g id="O">
            <path
              className="st0"
              d="M412.1,104.4C406.3,71.6,389,46.8,360.6,31c-27.8-15.6-58.6-20.6-91.5-14.8l-0.3,0.1
		c-19,4.1-36.1,10.6-50.7,19.2c-14.7,8.7-27.4,19.9-37.5,33.2c-20.5,26.8-27.7,58.2-21.5,93.4c5.2,29.7,20.8,52.7,46.2,68.3
		c18.4,11.3,38.9,17,61.3,17c8.2,0,16.7-0.8,25.4-2.3c19.4-3.4,37.1-9.4,52.6-17.7c15.6-8.4,29.3-19.4,40.9-32.7
		C409,167.7,417.9,137.3,412.1,104.4z M303,183.4c-9.6,1.7-17.5-2.7-23.7-13.2c-6.1-10-12-21.8-17.6-35.7
		c-5.6-13.8-9.2-24.8-10.6-33c-1.2-7-0.6-13,2.1-18c2.6-5,6.7-8.2,12.2-9.8c9.3-1.6,16.9,2,22.8,10.8c15.3,24,24.9,47.3,28.9,69.8
		C320.1,171.5,315.4,181.2,303,183.4z"
            />
            <path
              className="st1"
              d="M270.4,23.6c31.3-5.5,60.1-0.9,86.5,13.9c26.4,14.8,42.3,37.5,47.8,68.2c5.4,30.7-2.9,58.7-24.8,84.1
		c-22,25.4-51.7,41.3-89.1,47.9c-30.7,5.4-57.9,0.8-81.5-13.7c-23.6-14.6-37.9-35.6-42.7-63.2c-5.9-33.2,0.8-62.4,20.1-87.6
		C205.9,48,233.8,31.5,270.4,23.6z M265.3,73.7c-5.5,1.6-9.6,4.8-12.2,9.8c-2.6,5-3.3,11-2.1,18c1.4,8.2,5,19.2,10.6,33
		c5.6,13.8,11.5,25.7,17.6,35.7c6.2,10.5,14.1,14.9,23.7,13.2c12.4-2.2,17.1-11.9,14-29c-4-22.5-13.6-45.8-28.9-69.8
		C282.2,75.6,274.6,72,265.3,73.7z"
            />
            <path
              className="st0"
              d="M391.8,135c-2.8,1-8.9-31.3-39.1-61.4c-23.2-23.1-46.1-30.3-44.9-32.8c1.3-2.8,32.9,2,56,24
		C392.3,92.1,395,133.9,391.8,135z"
            />
          </g>
          <g id="Y">
            <path
              className="st0"
              d="M243.6,27c-6.9-4.5-16.9-7.7-30.7-9.6c-14-1.9-25.3-1.5-34.6,1.4c-13.9,4.2-17.9,12.5-18.7,18.8
		c-0.7,5,0.8,10.7,4.5,17.5c2.1,3.9,3,7.3,2.6,10.2c-0.3,1.9-1.4,6.4-6.5,15.6c-2.8,5.1-4.9,6.6-5.7,6.9c-0.8-0.7-3-3.3-5.9-11.5
		c-4.5-12.5-4.5-17.3-4.3-18.8c0-0.1,0.3-1.8,3.5-5.5c4.2-4.9,6.4-9,7-12.9c1-7-0.3-17.1-11.9-24.9c-7.8-5.3-19.3-8.9-35.2-11.1
		C94.7,1.3,81.9,0.3,69.5,0C54.8-0.3,43.8,1.2,35.6,4.5C23.4,9.4,19.9,17.7,19,23.8c-0.7,4.9,0.4,9.6,3.1,13.6
		c2.1,3.2,4.3,5.6,6.5,7.4c1.9,1.5,4.6,3.3,8.1,5.3c1.7,1.3,5.6,5.6,10.1,20.5L76.3,165c5.5,17.3,5.5,23.5,5.2,25.3
		c-0.4,3-1.4,5.2-3,6.8c-1.1,1.1-2.1,1.4-3.1,1.4c-0.2-0.3-0.4-0.8-0.7-1.7c-1.2-3.6-2.2-7.5-2.8-11.6c-0.9-5.9-3.7-11.7-8.4-17.1
		c-5-5.8-11.9-9.3-20.6-10.5c-10.3-1.4-19.8,1.2-28,7.7c-8.2,6.5-13,15.1-14.5,25.8c-1.9,14.2,2.4,26.8,13,37.4
		c10,10.1,23.7,16.4,40.7,18.7c4.7,0.6,9.2,1,13.7,1c8.5,0,16.7-1.2,24.4-3.5c11.8-3.5,22.4-9.5,31.5-17.8
		c8.6-7.9,16.1-16.3,22.4-25.2c6.1-8.7,12.4-19.1,18.7-31l31.7-59.7l0,0c8.2-15.6,14.7-26.4,19.4-32.1c4-4.8,8.1-7.6,12.7-8.5l0.2,0
		c8.1-2,13.6-4.1,17.3-6.8c3.1-2.2,7-6.6,8.1-14.5C255.7,40.3,251.8,32.4,243.6,27z"
            />
            <path
              className="st1"
              d="M190.1,107.6l-31.7,59.7c-6.2,11.7-12.3,21.8-18.2,30.2c-6,8.5-13.1,16.5-21.4,24
		c-8.3,7.5-17.8,12.9-28.6,16.1c-10.8,3.2-22.5,4-35,2.3c-15.4-2.1-27.5-7.6-36.4-16.6C9.9,214.4,6.2,204,7.9,192.2
		c1.2-8.8,5.1-15.8,11.7-21c6.6-5.2,14.1-7.3,22.4-6.2c6.9,0.9,12.2,3.6,15.9,8c3.7,4.4,6,8.8,6.7,13.4c0.7,4.6,1.7,8.9,3.1,12.9
		c1.4,4,3.4,6.2,6,6.6c3.8,0.5,7.1-0.6,10-3.3c2.9-2.7,4.7-6.5,5.3-11.2c0.7-5.2-1.1-14.7-5.5-28.6L54.1,68.3
		c-4-13.1-8.4-21.3-13.3-24.6c-3.4-1.9-5.8-3.5-7.5-4.8c-1.6-1.3-3.3-3.2-5-5.7c-1.7-2.5-2.3-5.3-1.9-8.4C28.1,12.7,42.4,7,69.3,7.5
		c12.3,0.2,24.7,1.2,37.2,2.9c29.1,4,42.7,13.2,40.7,27.6c-0.3,2.4-2.1,5.4-5.2,9c-3.2,3.7-4.9,6.8-5.3,9.4
		c-0.6,4.5,1,11.9,4.7,22.3c3.8,10.4,7.6,15.9,11.7,16.4c4.7,0.6,9.3-2.9,13.6-10.8c4.3-7.8,6.8-13.9,7.4-18.2
		c0.6-4.5-0.5-9.4-3.4-14.7c-2.9-5.3-4.1-9.7-3.6-13c0.8-5.9,5.3-10.1,13.5-12.6c8.2-2.5,18.6-2.9,31.4-1.1
		c25.1,3.4,36.8,11.2,35.1,23.2c-0.6,4.3-2.3,7.4-5.1,9.4c-2.8,2-7.7,3.9-14.7,5.6c-6.2,1.3-11.8,5-16.9,11.1
		C205.3,80.3,198.6,91.4,190.1,107.6z"
            />
            <path
              className="st0"
              d="M128.4,48.9c-0.3-1.8,0.6-6.8-0.9-11.5c-1.5-4.7-5.6-9.1-7-10.8c-3.7-4.6,6.3-3.6,12.5,0.1
		c5.2,3.1,5,5.7,5.2,7.9C138.5,39.5,129.4,54.3,128.4,48.9z"
            />
            <path
              className="st0"
              d="M232,57.6c-1.5-0.1-0.8-4.3-4.1-9.3c-4.1-6.2-10.1-7.6-9.6-9.2c0.4-1.3,5.2-2.5,8.8-2c1.2,0.2,4.7,0.7,6.9,2.2
		c2.7,1.8,3.6,3.7,4.2,6.5C239.4,51.9,233.8,57.7,232,57.6z"
            />
          </g>
        </svg>
        <div className="copyright">
          <p>ALL RIGHTS RESERVERD</p>
          <p>&copy;{currentYear}</p>
        </div>
      </Link>
    </div>
  );
};
