import { useContext, useState } from "react";

import { AuthContext } from "../../context/AuthContext";
import { useModal } from "../../context/ModalContext";

import { modifyProjectService } from "../../services";

import { Button } from "../Button/Button";

export const ModifyProject = ({ project }) => {
  const { token } = useContext(AuthContext);
  const [, setModal] = useModal();

  const [error, setError] = useState(null);

  const [title, setTitle] = useState("");
  const [author, setAuthor] = useState("");
  const [releaseYear, setReleaseYear] = useState("");
  const [projectDescription, setProjectDescription] = useState("");
  const [insertedMusic, setInsertedMusic] = useState("");
  const [musicGenre, setMusicGenre] = useState("");

  const [imagePreview, setImagePreview] = useState();

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    previewFile(file);
  };

  const previewFile = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setImagePreview(reader.result);
    };
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (title) formData.append("title", title);
    if (author) formData.append("author", author);
    if (releaseYear) formData.append("releaseYear", releaseYear);
    if (projectDescription)
      formData.append("projectDescription", projectDescription);
    if (insertedMusic) formData.append("insertedMusic", insertedMusic);
    if (musicGenre) formData.append("musicGenre", musicGenre);

    if (imagePreview) formData.append("imageName", imagePreview);

    const id = project.id;

    try {
      await modifyProjectService({
        formData,
        token,
        id,
      });
      e.target.reset();
      setImagePreview(null);
      setModal(false);
      window.location.reload();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="modify-project two-col-modal">
      <h1 className="modal-title">modify project</h1>

      <form className="modal-form" id="grid-form" onSubmit={handleFormSubmit}>
        <div className="col-1-grid-form">
          <label htmlFor="title-form">title</label>
          <input
            type="text"
            id="title-form"
            name="title-form"
            value={title}
            placeholder={project.title}
            onChange={(e) => setTitle(e.target.value)}
          />

          <label htmlFor="author-form">author</label>
          <input
            type="text"
            id="author-form"
            name="author-form"
            value={author}
            placeholder={project.author}
            onChange={(e) => setAuthor(e.target.value)}
          />

          <label htmlFor="release-year-form">release year</label>
          <input
            type="number"
            id="release-year-form"
            name="release-year-form"
            value={releaseYear}
            placeholder={project.releaseYear}
            onChange={(e) => setReleaseYear(e.target.value)}
          />

          <label htmlFor="description-form">project description</label>
          <textarea
            id="description-form"
            name="description-form"
            rows="4"
            value={projectDescription}
            placeholder={project.projectDescription}
            onChange={(e) => setProjectDescription(e.target.value)}
          />

          <label htmlFor="inserted-music-form">spotify/itunes insert</label>
          <input
            type="text"
            id="inserted-music-form"
            name="inserted-music-form"
            value={insertedMusic}
            placeholder={project.insertedMusic}
            onChange={(e) => setInsertedMusic(e.target.value)}
          />

          <label htmlFor="music-genre-form">music genre</label>
          <input
            type="text"
            id="music-genre-form"
            name="music-genre-form"
            value={musicGenre}
            placeholder={project.musicGenre}
            onChange={(e) => setMusicGenre(e.target.value)}
          />
        </div>

        <div className="col-2-grid-form">
          <label>art cover</label>
          <label htmlFor="art-cover-form" className="file-input-label">
            select file...
            <input
              type="file"
              name="art-cover-form"
              id="art-cover-form"
              accept="image/*"
              onChange={handleFileInputChange}
            />
          </label>

          {imagePreview ? (
            <figure>
              <img src={imagePreview} style={{ width: "100%" }} alt="Preview" />
            </figure>
          ) : (
            <figure>
              <img
                src={project.imageName}
                style={{ width: "100%" }}
                alt="Preview"
              />
            </figure>
          )}
        </div>

        <Button
          leftText={"submit changes"}
          rightText={String.fromCharCode(parseInt("&#8594;".substring(2), 10))}
          buttonStyle={"red-button"}
        />

        {error ? <p className="error-text">{error}</p> : null}
      </form>
    </div>
  );
};
