import "./ProjectPage.css";

import { useParams } from "react-router-dom";

import useProjects from "../../hooks/useProjects";

import { Loading } from "../../components/Loading/Loading";
import { ErrorMessage } from "../../components/ErrorMessage/ErrorMessage";

import { SingleProject } from "../../components/SingleProject/SingleProject.js";
import { Presentation } from "../../components/Presentation/Presentation";
import { Footer } from "../../components/Footer/Footer";

export const ProjectPage = () => {
  const { id } = useParams();

  const { project, loading, error } = useProjects(id);

  if (loading) return <Loading />;
  if (error) return <ErrorMessage message={error} />;

  return (
    <div className="project-page">
      <Presentation />
      <SingleProject project={project} />
      <Footer />
    </div>
  );
};
