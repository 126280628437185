import "./SingleProject.css";

import { useContext } from "react";
import { Link, NavLink } from "react-router-dom";

import { useModal } from "../../context/ModalContext";
import { AuthContext } from "../../context/AuthContext";

import { motion } from "framer-motion";
import Marquee from "react-fast-marquee";

import { ModifyProject } from "../ModifyProject/ModifyProject";
import { DeleteProject } from "../DeleteProject/DeleteProject";
import { Button } from "../Button/Button";

export const SingleProject = ({ project }) => {
  const [, setModal] = useModal();
  const { token } = useContext(AuthContext);

  return (
    <div className="single-project">
      {/* PROJECT HEADER */}
      <motion.div
        className="project-header"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, delay: 0.1 }}
      >
        <Link className="top-exit-button" to={`/`}>
          <p>&#8592;</p>
          <p> go back to projects</p>
        </Link>
        <h1 className="header-data">
          {project.author}
          <br></br>
          <span className="accent-color">&#62;</span> {project.title}
          <br></br>
          <span className="accent-text">{project.releaseYear}</span>
        </h1>
      </motion.div>
      {/* PROJECT DESCRIPTION */}
      <motion.p
        className="project-description"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, delay: 0.2 }}
      >
        {project.projectDescription}
      </motion.p>
      {/* JUST DESKTOP: USER INTERFACE FOR EDIT PROJECTS */}
      {token ? (
        <motion.div
          className="ui-project"
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4, delay: 0.2 }}
        >
          <p onClick={() => setModal(<ModifyProject project={project} />)}>
            <NavLink>
              <Button
                leftText="&#8594;"
                rightText="modify project"
                buttonStyle="no-box-button"
              />
            </NavLink>
          </p>

          <p onClick={() => setModal(<DeleteProject project={project} />)}>
            <NavLink>
              <Button
                leftText="&#8594;"
                rightText="delete project"
                buttonStyle="no-box-button"
              />
            </NavLink>
          </p>
        </motion.div>
      ) : null}
      {/* PROJECT INSERT */}
      <motion.div
        className="project-insert"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, delay: 0.2 }}
        dangerouslySetInnerHTML={{ __html: project.insertedMusic }}
      ></motion.div>
      {/* PROJECT GENRE */}
      <Marquee speed={40} direction="left" className="project-genre" autoFill>
        <motion.h2
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.4, delay: 0.3 }}
        >
          {project.musicGenre} /&nbsp;
        </motion.h2>
      </Marquee>
      {/* PROJECT ARTCOVER */}
      <motion.div
        className="project-artcover"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.4, delay: 0.1 }}
      >
        <img
          alt={project.id}
          /* src={`${process.env.REACT_APP_BACKEND}/uploads/${project.imageName}`} */
          src={project.imageName}
        />
      </motion.div>
      {/* JUST MOBILE: EXIT BUTTON */}
      <Link className="bottom-exit-button" to={`/`}>
        <p>&#8592;</p>
        <p>go back to projects</p>
      </Link>
    </div>
  );
};
