import "./ModifyUserData.css";

import { useContext, useState } from "react";

import { AuthContext } from "../../context/AuthContext";
import { useModal } from "../../context/ModalContext";
import { useUser } from "../../hooks/useUser";

import { modifyUserDataService } from "../../services";

import { Button } from "../Button/Button";

export const ModifyUserData = () => {
  const { user } = useUser();
  const { token } = useContext(AuthContext);
  const [, setModal] = useModal();

  const [presentationText, setPresentationText] = useState("");
  const [bioText, setBioText] = useState("");
  const [cityCountry, setCityCountry] = useState("");
  const [telephone, setTelephone] = useState("");

  const [url_customLink_1, set_url_customLink_1] = useState("");
  const [web_customLink_1, set_web_customLink_1] = useState("");
  const [user_customLink_1, set_user_customLink_1] = useState("");

  const [url_customLink_2, set_url_customLink_2] = useState("");
  const [web_customLink_2, set_web_customLink_2] = useState("");
  const [user_customLink_2, set_user_customLink_2] = useState("");

  const [url_customLink_3, set_url_customLink_3] = useState("");
  const [web_customLink_3, set_web_customLink_3] = useState("");
  const [user_customLink_3, set_user_customLink_3] = useState("");

  const [error, setError] = useState(null);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    if (presentationText) formData.append("presentationText", presentationText);
    if (bioText) formData.append("bioText", bioText);
    if (cityCountry) formData.append("cityCountry", cityCountry);
    if (telephone) formData.append("telephone", telephone);

    if (url_customLink_1) formData.append("url_customLink_1", url_customLink_1);
    if (web_customLink_1) formData.append("web_customLink_1", web_customLink_1);
    if (user_customLink_1)
      formData.append("user_customLink_1", user_customLink_1);

    if (url_customLink_2) formData.append("url_customLink_2", url_customLink_2);
    if (web_customLink_2) formData.append("web_customLink_2", web_customLink_2);
    if (user_customLink_2)
      formData.append("user_customLink_2", user_customLink_2);

    if (url_customLink_3) formData.append("url_customLink_3", url_customLink_3);
    if (web_customLink_3) formData.append("web_customLink_3", web_customLink_3);
    if (user_customLink_3)
      formData.append("user_customLink_3", user_customLink_3);

    try {
      await modifyUserDataService({ formData, token });
      setModal(false);
      window.location.reload();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="modify-user-data two-col-modal">
      <h1 className="modal-title">modify user data</h1>
      <form className="modal-form" id="grid-form" onSubmit={handleFormSubmit}>
        <div className="col-1-grid-form">
          <label htmlFor="presentation-text-form">presentation text</label>
          <textarea
            id="presentation-text-form"
            name="presentation-text-form"
            rows="8"
            value={presentationText}
            placeholder={user.presentationText}
            onChange={(e) => setPresentationText(e.target.value)}
          />

          <label htmlFor="biography-text-form">biography text</label>
          <textarea
            id="biography-text-form"
            name="biography-text-form"
            rows="8"
            value={bioText}
            placeholder={user.bioText}
            onChange={(e) => setBioText(e.target.value)}
          />

          <label htmlFor="city-country-form">city & country</label>
          <input
            type="text"
            id="city-country-form"
            name="city-country-form"
            value={cityCountry}
            placeholder={user.cityCountry}
            onChange={(e) => setCityCountry(e.target.value)}
          />

          <label htmlFor="telephone-form">telephone</label>
          <input
            type="text"
            id="telephone-form"
            name="telephone-form"
            value={telephone}
            placeholder={user.telephone}
            onChange={(e) => setTelephone(e.target.value)}
          />
        </div>

        {/* CUSTOM LINKS */}

        <div className="col-2-grid-form">
          {/* 1 CUSTOM LINK */}

          <label htmlFor="url_customLink_1">link 1</label>
          <input
            type="text"
            id="url_customLink_1"
            name="url_customLink_1"
            value={url_customLink_1}
            placeholder={`URL: ${
              user.url_customLink_1 ? user.url_customLink_1 : ""
            }`}
            onChange={(e) => set_url_customLink_1(e.target.value)}
          />

          <div className="link-names">
            <input
              type="text"
              name="web_customLink_1"
              value={web_customLink_1}
              placeholder={`WEB: ${
                user.web_customLink_1 ? user.web_customLink_1 : ""
              }`}
              onChange={(e) => set_web_customLink_1(e.target.value)}
            />

            <input
              type="text"
              name="user_customLink_1"
              value={user_customLink_1}
              placeholder={`USER: ${
                user.user_customLink_1 ? user.user_customLink_1 : ""
              }`}
              onChange={(e) => set_user_customLink_1(e.target.value)}
            />
          </div>

          {/* 2 CUSTOM LINK */}

          <label htmlFor="url_customLink_2">link 2</label>
          <input
            type="text"
            id="url_customLink_2"
            name="url_customLink_2"
            value={url_customLink_2}
            placeholder={`URL: ${
              user.url_customLink_2 ? user.url_customLink_2 : ""
            }`}
            onChange={(e) => set_url_customLink_2(e.target.value)}
          />

          <div className="link-names">
            <input
              type="text"
              name="web_customLink_2"
              value={web_customLink_2}
              placeholder={`WEB: ${
                user.web_customLink_2 ? user.web_customLink_2 : ""
              }`}
              onChange={(e) => set_web_customLink_2(e.target.value)}
            />

            <input
              type="text"
              name="user_customLink_2"
              value={user_customLink_2}
              placeholder={`USER: ${
                user.user_customLink_2 ? user.user_customLink_2 : ""
              }`}
              onChange={(e) => set_user_customLink_2(e.target.value)}
            />
          </div>

          {/* 3 CUSTOM LINK */}

          <label htmlFor="url_customLink_3">link 3</label>
          <input
            type="text"
            id="url_customLink_3"
            name="url_customLink_3"
            value={url_customLink_3}
            placeholder={`URL: ${
              user.url_customLink_3 ? user.url_customLink_3 : ""
            }`}
            onChange={(e) => set_url_customLink_3(e.target.value)}
          />

          <div className="link-names">
            <input
              type="text"
              name="web_customLink_3"
              value={web_customLink_3}
              placeholder={`WEB: ${
                user.web_customLink_3 ? user.web_customLink_3 : ""
              }`}
              onChange={(e) => set_web_customLink_3(e.target.value)}
            />

            <input
              type="text"
              name="user_customLink_3"
              value={user_customLink_3}
              placeholder={`USER: ${
                user.user_customLink_3 ? user.user_customLink_3 : ""
              }`}
              onChange={(e) => set_user_customLink_3(e.target.value)}
            />
          </div>
        </div>

        <Button
          leftText={"submit changes"}
          rightText={String.fromCharCode(parseInt("&#8594;".substring(2), 10))}
          buttonStyle={"red-button"}
        />

        {error ? <p className="error-text">{error}</p> : null}
      </form>
    </div>
  );
};
