import { useEffect, useState } from "react";
import { selectUserService } from "../services/index";

export const useUser = () => {
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const selectUser = async () => {
      try {
        setLoading(true);
        const data = await selectUserService();
        setUser(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    selectUser();
  }, []);

  return { user, setUser, error, loading };
};
