import { Link } from "react-router-dom";

export const ProjectThumbnail = ({ project }) => {
  return (
    <Link to={`/project/${project.id}`} className="project-thumbnail">
      <img
        className="img-project-thumbnail"
        alt={project.id}
        /* src={`${process.env.REACT_APP_BACKEND}/uploads/${project.imageName}`} */
        src={project.imageName}
      />
      <div className="text-project-thumbnail">
        <p>{project.author}</p>
        <p>
          <span className="accent-color">&#62;</span> {project.title}
        </p>
      </div>
    </Link>
  );
};
