import axios from "axios";

// SERVICES

//// PUBLIC

//////// GET PROJECTS

export const selectProyectsService = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND}/`);
    return response.data.data;
  } catch (error) {
    throw new Error("Error obtaining projects!" + error.message);
  }
};

//////// GET SINGLE PROJECT

export const selectSingleProyectService = async (id) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_BACKEND}/project/${id}`
    );
    return response.data.data;
  } catch (error) {
    throw new Error("Error obtaining project!" + error.message);
  }
};

//////// GET USER DATA

export const selectUserService = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND}/user/1`);
    return response.data.data;
  } catch (error) {
    throw new Error("Error obtaining user!" + error.message);
  }
};

//////// LOG IN USER

export const logInUserService = async ({ email, password }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/login`,
      {
        email,
        password,
      }
    );

    return response.data.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Network error. Please try again.");
    }
  }
};

//// PRIVATE

//////// POST PROJECT

export const postProjectService = async ({ formData, token }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/post-project`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Network error. Please try again.");
    }
  }
};

//////// MODIFY PROJECT

export const modifyProjectService = async ({ formData, token, id }) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND}/modify-project/${id}`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Network error. Please try again.");
    }
  }
};

//////// DELETE PROJECT

export const deleteProjectService = async ({ token, id }) => {
  try {
    const response = await axios.delete(
      `${process.env.REACT_APP_BACKEND}/delete-project/${id}`,
      {
        headers: {
          Authorization: token,
        },
      }
    );
    return response.data.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Network error. Please try again.");
    }
  }
};

//////// MODIFY USER DATA

export const modifyUserDataService = async ({ formData, token }) => {
  try {
    const response = await axios.put(
      `${process.env.REACT_APP_BACKEND}/modify-user`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Network error. Please try again.");
    }
  }
};

//////// MODIFY PHOTOS CAROUSEL

export const modifyPhotosCarouselService = async ({ formData, token }) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_BACKEND}/modify-photos`,
      formData,
      {
        headers: {
          Authorization: token,
        },
      }
    );

    return response.data.data;
  } catch (error) {
    if (error.response) {
      throw new Error(error.response.data.message);
    } else {
      throw new Error("Network error. Please try again.");
    }
  }
};
