import { useState, useContext } from "react";

import { AuthContext } from "../../context/AuthContext";
import { useModal } from "../../context/ModalContext";

import { Button } from "../Button/Button";
import { modifyPhotosCarouselService } from "../../services";

export const ModifyPhotosCarousel = ({ user }) => {
  const { token } = useContext(AuthContext);
  const [, setModal] = useModal();

  const [error, setError] = useState(null);

  const [photo_1_imagePreview, setPhoto_1_imagePreview] = useState(null || "");

  const handle_photo_1_InputChange = (e) => {
    const file = e.target.files[0];
    preview_photo_1(file);
  };

  const preview_photo_1 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPhoto_1_imagePreview(reader.result);
    };
  };

  const [photo_2_imagePreview, setPhoto_2_imagePreview] = useState(null || "");

  const handle_photo_2_InputChange = (e) => {
    const file = e.target.files[0];
    preview_photo_2(file);
  };

  const preview_photo_2 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPhoto_2_imagePreview(reader.result);
    };
  };

  const [photo_3_imagePreview, setPhoto_3_imagePreview] = useState(null || "");

  const handle_photo_3_InputChange = (e) => {
    const file = e.target.files[0];
    preview_photo_3(file);
  };

  const preview_photo_3 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPhoto_3_imagePreview(reader.result);
    };
  };

  const [photo_4_imagePreview, setPhoto_4_imagePreview] = useState(null || "");

  const handle_photo_4_InputChange = (e) => {
    const file = e.target.files[0];
    preview_photo_4(file);
  };

  const preview_photo_4 = (file) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setPhoto_4_imagePreview(reader.result);
    };
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    if (photo_1_imagePreview) formData.append("photo_1", photo_1_imagePreview);
    if (photo_2_imagePreview) formData.append("photo_2", photo_2_imagePreview);
    if (photo_3_imagePreview) formData.append("photo_3", photo_3_imagePreview);
    if (photo_4_imagePreview) formData.append("photo_4", photo_4_imagePreview);

    try {
      await modifyPhotosCarouselService({ formData, token });
      setPhoto_1_imagePreview(null);
      setPhoto_2_imagePreview(null);
      setPhoto_3_imagePreview(null);
      setPhoto_4_imagePreview(null);
      setModal(false);
      window.location.reload();
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="modify-photo-carousel one-col-modal">
      <h1 className="modal-title">modify carousel</h1>
      <form className="modal-form" id="grid-form" onSubmit={handleSubmit}>
        <div className="col-1-grid-form">
          <div className="file-selector">
            <label>photo 1</label>
            <label htmlFor="photo-carousel-1" className="file-input-label">
              select file...
              <input
                type="file"
                id="photo-carousel-1"
                name="photo-carousel-1"
                accept="image/*"
                onChange={handle_photo_1_InputChange}
              />
            </label>

            {photo_1_imagePreview ? (
              <figure>
                <img src={photo_1_imagePreview} alt="Preview" />
              </figure>
            ) : (
              <figure>
                <img src={user.photoNames[0]} alt="Preview" />
              </figure>
            )}
          </div>

          <div className="file-selector">
            <label>photo 3</label>
            <label htmlFor="photo-carousel-3" className="file-input-label">
              select file...
              <input
                type="file"
                id="photo-carousel-3"
                name="photo-carousel-3"
                accept="image/*"
                onChange={handle_photo_2_InputChange}
              />
            </label>

            {photo_2_imagePreview ? (
              <figure>
                <img src={photo_2_imagePreview} alt="Preview" />
              </figure>
            ) : (
              <figure>
                <img src={user.photoNames[2]} alt="Preview" />
              </figure>
            )}
          </div>
        </div>

        <div className="col-2-grid-form">
          <div className="file-selector">
            <label>photo 2</label>
            <label htmlFor="photo-carousel-2" className="file-input-label">
              select file...
              <input
                type="file"
                id="photo-carousel-2"
                name="photo-carousel-2"
                accept="image/*"
                onChange={handle_photo_3_InputChange}
              />
            </label>

            {photo_3_imagePreview ? (
              <figure>
                <img src={photo_3_imagePreview} alt="Preview" />
              </figure>
            ) : (
              <figure>
                <img src={user.photoNames[1]} alt="Preview" />
              </figure>
            )}
          </div>

          <div className="file-selector">
            <label>photo 4</label>
            <label htmlFor="photo-carousel-4" className="file-input-label">
              select file...
              <input
                type="file"
                id="photo-carousel-4"
                name="photo-carousel-4"
                accept="image/*"
                onChange={handle_photo_4_InputChange}
              />
            </label>

            {photo_4_imagePreview ? (
              <figure>
                <img src={photo_4_imagePreview} alt="Preview" />
              </figure>
            ) : (
              <figure>
                <img src={user.photoNames[3]} alt="Preview" />
              </figure>
            )}
          </div>
        </div>

        <Button
          leftText={"submit changes"}
          rightText={String.fromCharCode(parseInt("&#8594;".substring(2), 10))}
          buttonStyle={"red-button"}
          type="submit"
        />

        {error ? <p className="error-text">{error}</p> : null}
      </form>
    </div>
  );
};
