import "./ContactLink.css";

export const ContactLink = ({ linkTextL, linkTextR, linkUrl, isLowerCase }) => {
  const openLink = () => {
    window.open(linkUrl, "_blank");
  };

  return (
    <div
      className="contact-link"
      onClick={openLink}
      id={linkUrl ? "with-link" : null}
    >
      <p>{linkTextL}</p>
      <p id={isLowerCase ? isLowerCase : null}>{linkTextR}</p>
    </div>
  );
};
