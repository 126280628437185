import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";

export const About = ({ user }) => {
  const { photoNames } = user;

  const swiperParams = {
    slidesPerView: 1,
    loop: true,
  };

  return (
    <div className="about">
      <Swiper {...swiperParams} className="slider-photos-carousel">
        {photoNames.map((photoName, index) => (
          <SwiperSlide
            key={index}
            className="slide-photo-carousel"
            style={{
              backgroundImage: `url(${photoName})`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          ></SwiperSlide>
        ))}
      </Swiper>

      <div className="text-about">
        <p>About</p>
        <p>{user.bioText}</p>
      </div>
    </div>
  );
};
