import { useEffect, useState } from "react";
import {
  selectProyectsService,
  selectSingleProyectService,
} from "../services/index";

const useProjects = (id) => {
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const selectProjects = async () => {
      try {
        setLoading(true);
        if (id) {
          const data = await selectSingleProyectService(id);
          setProject(data);
        } else {
          const data = await selectProyectsService();
          setProjects(data);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };
    selectProjects();
  }, [id]);

  /* const addProject = (data) => {
    setProject([data, ...project]);
  }; */

  return {
    /*  addProject, */
    projects,
    setProjects,
    project,
    setProject,
    error,
    loading,
  };
};

export default useProjects;
