import "./App.css";

import { Routes, Route } from "react-router-dom";

import { useModal } from "./context/ModalContext";

import { HomePage } from "./pages/HomePage/HomePage";
import { ProjectPage } from "./pages/ProjectPage/ProjectPage";

import { Modal } from "./components/Modal/Modal";

function App() {
  const [modal] = useModal();

  return (
    <div className="App">
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/project/:id" element={<ProjectPage />} />
      </Routes>
      {modal && <Modal>{modal}</Modal>}
    </div>
  );
}

export default App;
