import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AuthContext } from "../../context/AuthContext";
import { useModal } from "../../context/ModalContext";

import { deleteProjectService } from "../../services";

import { Button } from "../Button/Button";

export const DeleteProject = ({ project }) => {
  const navigate = useNavigate();
  const { token } = useContext(AuthContext);
  const [, setModal] = useModal();

  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState(null);

  const handleDelete = async () => {
    const id = project.id;
    try {
      setIsDeleting(true);

      await deleteProjectService({ token, id });
      setModal(false);
      navigate("/");
      window.location.reload();
    } catch (error) {
      setError(error.message);
    } finally {
      setIsDeleting(false);
    }
  };

  const handleCloseModal = () => {
    setModal(false);
  };

  return (
    <div className="delete-project one-col-modal">
      <h1 className="modal-title">delete project</h1>
      <p>Sure you want to delete this project?</p>
      <Button
        leftText={String.fromCharCode(parseInt("&#8592;".substring(2), 10))}
        rightText={"no, go back"}
        buttonStyle={"red-button"}
        onClick={handleCloseModal}
      />
      <Button
        leftText={isDeleting ? "deleting..." : "yes, delete!"}
        rightText={String.fromCharCode(parseInt("&#8594;".substring(2), 10))}
        buttonStyle={"white-button"}
        onClick={handleDelete}
        disabled={isDeleting}
      />
      {error && <p className="error-text">{error}</p>}
    </div>
  );
};
